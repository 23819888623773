import * as React from "react";
import {Link} from "gatsby";

import Layout from "../components/layout";
import Section from "../components/section";
import TemplateHead from "../components/template-head";

const PageError404 = () => {
  return (
    <Layout>
      <Section
        sectionClass={
          "pl-4 pr-4 section-container bg-lightgrey section-container--404"
        }
      >
        <div className="page-404-inner-wrapper">
          <div className="row no-gutters">
            <div className="col-12 col-sm-12 col-md-10 col-lg-7 col-xl-6 mx-auto text-center">
              <img
                src="/images/broken_pencil_404.png"
                placeholder="blurred"
                srcSet="/images/broken_pencil_404.png 1x, /images/broken_pencil_404@2x.png 2x"
                alt="Broken pencil"
                width={360}
                height={143}
                className="page-404-image"
              />
              <div className="title-404">
                <span>404</span>
              </div>
              <div className="title-inner">
                <h1 className="h3">
                  The page could not be found
                </h1>
              </div>
              <p className="page-404-text">
                Sorry, the page you are trying to reach cannot
                be found. If you got here by clicking on a link
                on our website, please reach out to{" "}
                <a href="mailto:hello@fenixfuneral.co.uk">hello@fenixfuneral.co.uk</a>
              </p>
              <p className="page-404-text">Proceed:</p>
              <Link
                id="use_link_locch"
                className="page-404-useful-links"
                to="/funeral-directors/"
              >
                <span>
                  For those who want to find their nearest
                  funeral home
                </span>
                <div className="page-404-useful-links-icon-wrap">
                  <i className="fenix-icon icon-right"></i>
                </div>
              </Link>
              <Link
                id="use_link_omfenix"
                className="page-404-useful-links"
                to="/about-fenix/"
              >
                <span>
                  For those who want to know more about Fenix
                </span>
                <div className="page-404-useful-links-icon-wrap">
                  <i className="fenix-icon icon-right"></i>
                </div>
              </Link>
              <Link className="page-404-useful-links" to="/">
                Go to front page
                <div className="page-404-useful-links-icon-wrap">
                  <i className="fenix-icon icon-right"></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default PageError404;

export function Head() {
  return (
    <TemplateHead
      title="404: Page not found - Fenix Funeral Directors"
    />
  );
}